import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Textfit } from 'react-textfit';

import Box from '@mui/material/Box';

import { BgImage, Paper, Typography } from './theme';

function MarkdownParagraph({ children }) {
  return (
    <Typography variant="body2" component="div" paragraph sx={{ fontFamily: 'Flicker' }}>
      <Textfit mode="multi" throttle={500}>
        <Box component="span" sx={{ height: '280px', display: 'block' }}>
          {children}
        </Box>
      </Textfit>
    </Typography>
  );
}

function MarkdownStrong({ children }) {
  return (
    <Box component="strong" sx={(theme) => ({ color: theme.palette.secondary.main, fontWeight: 500 })}>
      {children}
    </Box>
  );
}

const components = {
  p: MarkdownParagraph,
  strong: MarkdownStrong,
};

function Testimonial({ testimonialCompany, testimonialImage, testimonialName, testimonialQuote, testimonialAllign }) {
  return (
    <Paper
      elevation={4}
      sx={(theme) => ({
        display: 'block',
        overflow: 'hidden',
        textAlign: 'left',
        textDecoration: 'none',
        padding: '0',
        position: 'relative',
        height: '383px',
        width: '613px',
        [theme.breakpoints.down('sm')]: {
          width: 'calc(100vw - 65px)',
        },
      })}
    >
      <Box
        sx={[
          (theme) => ({
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '50%',
            display: 'block',
            height: '700px',
            width: '700px',
            position: 'absolute',
            right: '-430px',
            top: '-90px',
            [theme.breakpoints.down('sm')]: {
              top: '105px',
              right: '-162px',
              height: '350px',
              width: '350px',
            },
          }),
          testimonialAllign === 'Left' &&
            ((theme) => ({
              left: '-430px',
              right: 'auto',
              [theme.breakpoints.down('sm')]: {
                left: '-162px',
                right: 'auto',
              },
            })),
        ]}
      />
      <BgImage
        image={testimonialImage.localFile.childImageSharp.gatsbyImageData}
        sx={[
          (theme) => ({
            '&:before, &:after': {
              height: '85% !important',
              bottom: '0',
              top: 'auto !important',
              backgroundPosition: 'right !important',
              backgroundSize: 'contain !important',
            },
            [theme.breakpoints.down('sm')]: {
              '&:before, &:after': {
                height: '60% !important',
              },
            },
          }),
          testimonialAllign === 'Left' && {
            '&:before, &:after': {
              backgroundPosition: 'left !important',
            },
          },
        ]}
      >
        <Box
          component="article"
          sx={[
            (theme) => ({
              height: '383px',
              width: '613px',
              [theme.breakpoints.down('sm')]: {
                width: 'calc(100vw - 65px)',
              },
              padding: '24px 45% 24px 24px',
              position: 'relative',
            }),
            testimonialAllign === 'Left' && { padding: '24px 24px 24px 45%', textAlign: 'right' },
          ]}
        >
          <ReactMarkdown components={components}>{testimonialQuote.data.Quote}</ReactMarkdown>
          <Typography
            variant="h2"
            sx={[
              (theme) => ({
                fontSize: theme.typography.pxToRem(18),
                fontWeight: theme.typography.fontWeightMedium,
                lineHeight: '1.25em',
                bottom: '25px',
                left: '24px',
                position: 'absolute',
                width: 'auto',
              }),
              testimonialAllign === 'Left' && { left: 'auto', right: '24px', textAlign: 'right' },
            ]}
          >
            {testimonialName}
            <br />
            {testimonialCompany}
          </Typography>
        </Box>
      </BgImage>
    </Paper>
  );
}

Testimonial.propTypes = {
  testimonialCompany: PropTypes.string.isRequired,
  testimonialAllign: PropTypes.string.isRequired,
  testimonialName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  testimonialQuote: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  testimonialImage: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default Testimonial;
