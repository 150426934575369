import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { useIntl } from 'gatsby-plugin-react-intl';

import { Box, Hidden } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import PaperSection from './layout/PaperSection';
import BlockHeadline from './layout/BlockHeadline';
import { Link } from './theme';

import svgIcons from '../constants/svgIcons';

// root: {
//   backgroundColor: theme.palette.common.white,
//   paddingBottom: '14px',
//   [theme.breakpoints.up('sm')]: {
//     paddingBottom: '28px',
//   },
//   [theme.breakpoints.up('md')]: {
//     paddingBottom: '64px',
//   },
// },
// wrapper: {
//   [theme.breakpoints.up('md')]: {
//     margin: '0 -56px',
//   },
// },
// contentWrapper: {
//   [theme.breakpoints.up('sm')]: {
//     display: 'flex',
//   },
// },

function Newsletter({ themeBase }) {
  const intl = useIntl();
  /*  eslint-disable  dot-notation, camelcase, no-unused-vars, no-undef, func-names,
      no-param-reassign, no-var, prefer-rest-params, no-shadow, react/no-this-in-sfc,
      yoda, vars-on-top, no-bitwise, prefer-template
  */
  useEffect(() => {
    (function (m, a, i, l, e, r) {
      m['MailerLiteObject'] = e;
      function f() {
        var c = { a: arguments, q: [] };
        var r = this.push(c);
        return 'number' != typeof r ? r : f.bind(c.q);
      }
      f.q = f.q || [];
      m[e] = m[e] || f.bind(f.q);
      m[e].q = m[e].q || f.q;
      r = a.createElement(i);
      var _ = a.getElementsByTagName(i)[0];
      r.async = 1;
      r.src = l + '?v' + ~~(new Date().getTime() / 1000000);
      _.parentNode.insertBefore(r, _);
    })(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');

    var ml_account = ml('accounts', '573309', 'f9f9w5m4l8', 'load');
  });

  return (
    <PaperSection themeBase={themeBase}>
      <Grid
        container
        sx={(theme) => ({
          padding: '32px',
          textAlign: 'center',
          [theme.breakpoints.up('sm')]: {
            padding: '60px 48px',
          },
        })}
      >
        <Grid
          item
          xs={12}
          sm={5}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '-48px' }}
        >
          <Hidden smDown>
            <ReactSVG src={svgIcons.newsletter} />
          </Hidden>
        </Grid>
        <Grid item xs={12} sm={7}>
          <BlockHeadline
            sizeRestriction="none"
            overline={intl.formatMessage({ id: 'newsletter.title' })}
            content={intl.formatMessage({ id: 'newsletter.content' })}
            variant="h1"
          />
          {process.env.CURRENT_LANGUAGE === 'en' ? (
            <div
              className="ml-form-embed"
              id="newsletterMlFormEmbed"
              data-account="573309:f9f9w5m4l8"
              data-form="4047268:y9q0m6"
            />
          ) : (
            <div
              className="ml-form-embed"
              id="newsletterMlFormEmbed"
              data-account="573309:f9f9w5m4l8"
              data-form="1724748:t7k2u4"
            />
          )}
          <Box
            component="p"
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(12),
              lineHeight: '1.25em',
              letterSpacing: '0.23px',
              margin: '4px 0 32px 0px',
              textAlign: 'left',
              [theme.breakpoints.up('sm')]: {
                margin: '8px 0 0',
              },
            })}
          >
            {intl.formatMessage({ id: 'newsletter.signup' })}{' '}
            <Link underline="none" color="secondary" to="/privacy/">
              {intl.formatMessage({ id: 'navigation.privacy' })}
            </Link>
            .
          </Box>
        </Grid>
      </Grid>
    </PaperSection>
  );
}

Newsletter.propTypes = {
  themeBase: PropTypes.oneOf(['light', 'dark']),
};

Newsletter.defaultProps = {
  themeBase: 'dark',
};

export default Newsletter;
