import React from 'react';
import PropTypes from 'prop-types';
import Whirligig from 'react-whirligig';

import Box from '@mui/material/Box';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import BlockHeadline from './layout/BlockHeadline';
import Container from './theme/Container';
import IconButton from './theme/IconButton';
import Testimonial from './Testimonial';

function Testimonials({ overline, testimonials, title, isMagenta }) {
  let whirligig;
  const next = () => whirligig.next();
  const prev = () => whirligig.prev();

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        padding: '100px 0 60px',
        marginTop: '50px',
      })}
      component="section"
    >
      <Container>
        <BlockHeadline
          isMagenta={isMagenta}
          overline={overline}
          content={title}
          variant="h1"
          sizeRestriction="normal"
        />
      </Container>
      <Box
        sx={(theme) => ({
          margin: '30px auto 0',
          maxWidth: '1290px',
          textAlign: 'center',
          [theme.breakpoints.up('md')]: {
            marginLeft: '135px',
          },
          [theme.breakpoints.up('lg')]: {
            marginLeft: 'auto',
          },
        })}
      >
        <Whirligig
          visibleSlides={1}
          preventScroll
          gutter="0"
          ref={(_whirligigInstance) => {
            whirligig = _whirligigInstance;
          }}
        >
          {testimonials.map((testimonial) => {
            return (
              <Box
                key={testimonial.testimonialId}
                sx={(theme) => ({
                  padding: '16px',
                  [theme.breakpoints.down('md')]: {
                    padding: '24px',
                  },
                })}
              >
                <Testimonial {...testimonial} />
              </Box>
            );
          })}
        </Whirligig>
      </Box>
      <Container sx={{ marginTop: '24px' }}>
        <IconButton aria-label="previous" color="primary" onClick={prev} size="large">
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton aria-label="next" color="primary" onClick={next} size="large">
          <NavigateNextIcon />
        </IconButton>
      </Container>
    </Box>
  );
}

Testimonials.propTypes = {
  overline: PropTypes.string,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  testimonials: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMagenta: PropTypes.bool,
};

Testimonials.defaultProps = {
  overline: '',
  title: '',
  isMagenta: true,
};

export default Testimonials;
