import React from 'react';
import PropTypes from 'prop-types';

import { Box, Tabs, Tab } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Container from './theme/Container';
import HeroContent from './HeroContent';
import AppStoreLinks from './AppStoreLinks';
import { GatsbyImage } from './theme';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index ? children : null}
    </div>
  );
}

// function ImageName(images, filename) {
//   if (images) {
//     const item = images.find((x) => x.heroImageFilename === filename);
//     return item ? item.heroImageTitle : '';
//   }
// }

// const StyledTab = styled(Tabs)(({ theme }) => ({}));

function Hero({ bgColorInverted, content, contentAlign, download, isMagenta }) {
  const [value, setValue] = React.useState(0);

  const imagesSortedByOrder = content.heroImage;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabs = () => {
    return (
      imagesSortedByOrder &&
      imagesSortedByOrder.length > 1 && (
        <Tabs
          value={value}
          onChange={handleChange}
          orientation="vertical"
          textColor={isMagenta ? 'secondary' : '#27c299'}
          sx={{
            marginTop: '42px',
            '&.MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
          {imagesSortedByOrder.map((currentImage) => {
            return (
              <Tab
                label={currentImage.tabText}
                key={currentImage.id}
                sx={(theme) => ({
                  borderLeft: `10px solid ${isMagenta ? '#d82772' : '#27c299'}`,
                  borderBottom: 'none',
                  height: '56px',
                  maxWidth: '320px',
                  lineHeight: theme.typography.pxToRem(23),
                  fontSize: theme.typography.pxToRem(16),
                  marginBottom: '32px',
                  textAlign: 'left',
                  alignItems: 'start',
                  opacity: '0.56',
                  backgroundImage: `linear-gradient(90deg, ${
                    isMagenta ? 'rgba(216, 39, 114, 0.19)' : 'rgba(39, 194, 153, 0.19)'
                  }, transparent)`,
                  color: isMagenta ? theme.palette.secondary.main : theme.palette.green.main,
                  '&.Mui-selected': {
                    opacity: '1',
                  },
                  '&.MuiTab-wrapped': {
                    alignItems: 'flex-start',
                  },
                })}
              />
            );
          })}
        </Tabs>
      )
    );
  };

  return (
    <Box
      sx={[
        { padding: '60px 0' },
        bgColorInverted &&
          ((theme) => ({ color: theme.palette.text.secondary, backgroundColor: theme.palette.background.paper })),
      ]}
    >
      <Container>
        {contentAlign === 'center' && (
          // Center-Layout
          <Grid container alignItems="center">
            <Grid sx={{ paddingTop: '30px', maxWidth: '800px', margin: '0 auto' }} xs={12}>
              <HeroContent
                isMagenta={isMagenta}
                heroText={content.heroText}
                heroOverline={content.heroOverline}
                heroTitle={content.heroTitle}
                bgColorInverted={bgColorInverted}
              />
            </Grid>
          </Grid>
        )}
        {contentAlign !== 'center' && (
          // Right-Left-Layout
          <Grid
            sx={[
              contentAlign === 'right' &&
                ((theme) => ({
                  [theme.breakpoints.up('sm')]: {
                    flexFlow: 'row-reverse',
                  },
                })),
            ]}
            justifyContent="space-between"
            alignItems="center"
            container
          >
            <Grid xs={12} sm={5} md={6}>
              <Box
                sx={[
                  (theme) => ({
                    marginTop: '50px',
                    marginBottom: '50px',
                    maxWidth: '520px',
                    [theme.breakpoints.up('md')]: {
                      marginTop: '80px',
                      marginBottom: '80px',
                    },
                    [theme.breakpoints.down('md')]: {
                      marginTop: '0px',
                      marginBottom: '0px',
                    },
                  }),
                  contentAlign === 'right' && { float: 'right' },
                ]}
              >
                <HeroContent
                  isMagenta={isMagenta}
                  heroText={content.heroText}
                  heroOverline={content.heroOverline}
                  heroTitle={content.heroTitle}
                  bgColorInverted={bgColorInverted}
                />
                {download && <AppStoreLinks inline />}
                {renderTabs()}
              </Box>
            </Grid>
            {imagesSortedByOrder && (
              <Grid xs={12} sm={7} md={6}>
                {imagesSortedByOrder.length === 1 && (
                  <GatsbyImage
                    image={imagesSortedByOrder[0].image.localFile.childImageSharp.gatsbyImageData}
                    alt={
                      imagesSortedByOrder[0].image.alternativeText ? imagesSortedByOrder[0].image.alternativeText : ''
                    }
                    sx={{ maxWidth: '100%' }}
                  />
                )}
                {imagesSortedByOrder.length > 1 &&
                  imagesSortedByOrder.map((currentImage, index) => (
                    <TabPanel key={currentImage.id} value={value} index={index}>
                      <GatsbyImage
                        image={currentImage.image.localFile.childImageSharp.gatsbyImageData}
                        alt={currentImage.image.alternativeText ? currentImage.image.alternativeText : ''}
                      />
                    </TabPanel>
                  ))}
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </Box>
  );
}

Hero.propTypes = {
  bgColorInverted: PropTypes.bool,
  contentAlign: PropTypes.string,
  download: PropTypes.bool,
  isMagenta: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.object.isRequired,
};

Hero.defaultProps = {
  bgColorInverted: false,
  contentAlign: 'left',
  download: false,
  isMagenta: true,
};

export default Hero;
