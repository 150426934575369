import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { Typography, ExternalLink, ReactSVG } from './theme';
import svgIcons from '../constants/svgIcons';
import StrapiMarkdown from './StrapiMarkdown';

function Fact({ color, content, linear, fullContent, isMagenta }) {
  const { headline, icon, text, link } = content;

  return (
    <Grid
      sx={[
        (theme) => ({
          marginBottom: '48px',
          [theme.breakpoints.up('sm')]: {
            padding: '0 25px',
          },
          [theme.breakpoints.up('md')]: {
            marginBottom: '64px',
            padding: '0 50px',
          },
        }),
        link && { marginBottom: '108px' },
      ]}
      item
      xs={12}
      // eslint-disable-next-line
      sm={linear ? 4 : fullContent ? 'auto' : 6}
    >
      <Box sx={[{ display: 'flex', height: '100%', position: 'relative' }, linear && { flexDirection: 'column' }]}>
        <Box
          sx={(theme) => ({
            marginRight: '18px',
            [theme.breakpoints.up('md')]: {
              marginRight: '40px',
            },
          })}
        >
          <ReactSVG
            sx={(theme) => ({
              alignItems: 'center',
              backgroundColor: theme.palette.common.white,
              border: '2px solid #E8E8E8',
              borderRadius: '50%',
              display: 'flex',
              height: '60px',
              justifyContent: 'center',
              marginBottom: '18px',
              width: '60px',
              [theme.breakpoints.up('md')]: {
                height: '80px',
                width: '80px',
              },
              '& > div': {
                display: 'inherit',
              },
              '& svg': {
                height: '28px',
                width: '28px',
                [theme.breakpoints.up('md')]: {
                  height: '48px',
                  width: '48px',
                },
              },
            })}
            src={svgIcons[`${icon}`]}
          />
        </Box>
        <Box>
          <Typography
            variant="h3"
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(16),
              fontStyle: 'italic',
              fontWeight: theme.typography.fontWeightBolder,
              margin: '0 0 6px',
              textTransform: 'uppercase',
              [theme.breakpoints.up('md')]: {
                fontSize: theme.typography.pxToRem(18),
              },
            })}
          >
            {headline}
          </Typography>
          {/* <Typography
            // eslint-disable-next-line react/no-array-index-key
            color={color}
            variant="body1"
            component="span"
            dangerouslySetInnerHTML={{ __html: text.data.text }}
            sx={{ display: 'block', marginBottom: '24px' }}
          /> */}
          <Box
            sx={(theme) => ({
              '& a': {
                color: isMagenta ? theme.palette.link.primary : theme.palette.link.secondary,
                '&:hover': {
                  color: isMagenta ? theme.palette.link.primary : theme.palette.link.secondary,
                },
              },
              '& li a': {
                color: isMagenta ? theme.palette.link.primary : theme.palette.link.secondary,
              },
            })}
          >
            <StrapiMarkdown source={text.data.text} />
          </Box>
          {link && (
            <Typography variant="body1">
              <ExternalLink
                isMagenta={isMagenta}
                sx={{ position: 'absolute', bottom: '-3.5em', left: '0' }}
                href={link.url}
              >
                {link.text}
              </ExternalLink>
            </Typography>
          )}
        </Box>
      </Box>
    </Grid>
  );
}

Fact.propTypes = {
  // icon: PropTypes.object.isRequired,
  linear: PropTypes.bool,
  fullContent: PropTypes.bool,
  color: PropTypes.string,
  isMagenta: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.object.isRequired,
};

Fact.defaultProps = {
  linear: false,
  fullContent: false,
  color: '',
  isMagenta: true,
};

export default Fact;
