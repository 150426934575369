import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { Box, Hidden } from '@mui/material';
import StrapiMarkdown from './StrapiMarkdown';

import { Container, Button, Typography, BgImage } from './theme';

const query = graphql`
  {
    ridFiles: allFile(filter: { relativeDirectory: { eq: "images/world-record/rid-logo" } }) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`;

const headlineHighlightStyle = (theme) => ({ color: theme.palette.secondary.main });

const headlineStyles = (theme) => ({
  '& p, span': {
    fontFamily: 'Flicker',
    fontSize: theme.typography.pxToRem(34),
    fontWeight: 'normal',
    lineHeight: '1.3em',
    margin: '40px 0 32px',
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(58),
      marginTop: '50px',
      maxWidth: '590px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(63),
      marginTop: '130px',
      maxWidth: '640px',
    },
  },
});

const buttonStyles = { marginRight: '20px', marginBottom: '20px' };

function RidImage() {
  const {
    ridFiles: { edges: allBannerImages },
  } = useStaticQuery(query);

  const filteredForLocale = allBannerImages.filter((entry) => entry.node.name.endsWith(process.env.CURRENT_LANGUAGE));

  const xsImage = filteredForLocale.find((x) => x.node.name.startsWith('rid_xs')).node.publicURL;
  const smImage = filteredForLocale.find((x) => x.node.name.startsWith('rid_sm')).node.publicURL;
  const mdLogo = filteredForLocale.find((x) => x.node.name.startsWith('rid_md')).node.publicURL;

  const alt =
    process.env.CURRENT_LANGUAGE === 'de'
      ? 'Offizieller Rekordversuch mit dem Rekord Institut Deutschland'
      : 'Official Record Attempt with the Rekord Institute Germany';
  // const BannerImageXS = node.childImageSharp.gatsbyImageData

  return (
    <div>
      <Hidden smUp>
        <img src={xsImage} alt={alt} />
      </Hidden>
      <Hidden mdUp smDown>
        <img src={smImage} alt={alt} />
      </Hidden>
      <Hidden mdDown>
        <img src={mdLogo} alt={alt} />
      </Hidden>
    </div>
  );
}

/* TODO i18n that and format correctly */
function SaveTheDate({ backgroundImage, title, text, showButton, buttonText, buttonLink, isMagenta }) {
  return (
    <Box
      sx={(theme) => ({
        padding: '5px 0 30px',
        textAlign: 'left',
        [theme.breakpoints.up('xs')]: {
          padding: '0 0',
        },
      })}
    >
      <Container>
        <BgImage
          image={backgroundImage.localFile.childImageSharp.gatsbyImageData}
          sx={(theme) => ({
            backgroundPosition: 'center top',
            backgroundSize: 'contain',
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
              alignItems: 'flex-end',
            },
          })}
        >
          <Box sx={{ flex: '1 1 100%', overflow: 'hidden' }}>
            <Box component="h1" sx={headlineStyles}>
              <StrapiMarkdown source={title} />
            </Box>
            <Typography
              color="textPrimary"
              paragraph
              variant="body1"
              sx={(theme) => ({
                margin: '0 auto 24px',
                maxWidth: '395px',
                [theme.breakpoints.up('sm')]: {
                  margin: '0 0 32px',
                },
              })}
            >
              <StrapiMarkdown source={text} />
            </Typography>

            {showButton ? (
              <Box
              // sx={(theme) => ({
              //   marginBottom: '58px',
              //   [theme.breakpoints.up('sm')]: {
              //     marginBottom: '50px',
              //   },
              //   [theme.breakpoints.up('md')]: {
              //     marginBottom: '110px',
              //   },
              // })}
              >
                {isMagenta ? (
                  <Button color="secondary" size="small" variant="contained" sx={buttonStyles} to={buttonLink}>
                    {buttonText}
                  </Button>
                ) : (
                  <Typography
                    sx={{
                      '& > a': {
                        backgroundColor: '#27c299',
                        color: '#FFFFFF',
                        '&:hover, &:focus': {
                          backgroundColor: '#05a077',
                          textDecoration: 'none',
                        },
                      },
                    }}
                  >
                    <Button color="secondary" size="small" variant="contained" sx={buttonStyles} to={buttonLink}>
                      {buttonText}
                    </Button>
                  </Typography>
                )}
              </Box>
            ) : null}
          </Box>
          <Box
            sx={(theme) => ({
              flex: '0 0 228px',
              marginBottom: '43px',
              [theme.breakpoints.down('sm')]: {
                flex: '0 0 95px',
                marginBottom: '12px',
              },
              [theme.breakpoints.up('sm')]: {
                marginTop: '130px',
              },
            })}
          >
            <RidImage />
          </Box>
        </BgImage>
      </Container>
    </Box>
  );
}

SaveTheDate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  backgroundImage: PropTypes.object.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  isMagenta: PropTypes.bool,
};

SaveTheDate.defaultProps = {
  title: '',
  text: '',
  showButton: true,
  buttonText: '',
  buttonLink: '',
  isMagenta: true,
};

export default SaveTheDate;
