import { styled } from '@mui/material/styles';
import { IconButton } from 'gatsby-theme-material-ui';

export default styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.text.primary,
  marginRight: '8px',
  '&:hover, &:focus': {
    backgroundColor: theme.palette.primary.light,
  },
}));
