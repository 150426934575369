import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Container from './theme/Container';
import BlockHeadline from './layout/BlockHeadline';
import Fact from './Fact';

function Facts({ title, overline, facts, bgColorInverted, linear, headless, isMagenta }) {
  return (
    <Box
      sx={[
        (theme) => ({
          padding: '50px 0 2px',
          [theme.breakpoints.up('sm')]: {
            padding: '60px 0 12px',
          },
          [theme.breakpoints.up('md')]: {
            padding: '100px 0 36px',
          },
        }),
        bgColorInverted &&
          ((theme) => ({ backgroundColor: theme.palette.background.paper, color: theme.palette.text.secondary })),
      ]}
    >
      <Container>
        <Grid
          sx={(theme) => ({
            [theme.breakpoints.up('sm')]: {
              margin: '0 -25px',
              width: 'calc(100% + 50px)',
            },
            [theme.breakpoints.up('md')]: {
              margin: '0 -50px',
              width: 'calc(100% + 100px)',
            },
            '& h2': {
              marginBottom: '42px',
              [theme.breakpoints.up('sm')]: {
                paddingLeft: '25px',
              },
              [theme.breakpoints.up('md')]: {
                marginBottom: '64px',
                paddingLeft: '50px',
              },
            },
          })}
          container
        >
          {!headless && (
            <BlockHeadline
              isMagenta={isMagenta}
              overline={overline}
              content={title}
              variant="h2"
              sizeRestriction="normal"
            />
          )}
          {facts.map((fact, index) => (
            <Fact
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              content={fact}
              linear={linear}
              isMagenta={isMagenta}
              color={bgColorInverted ? 'textSecondary' : 'textPrimary'}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

Facts.propTypes = {
  bgColorInverted: PropTypes.bool,
  linear: PropTypes.bool,
  title: PropTypes.string,
  overline: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  facts: PropTypes.array.isRequired,
  headless: PropTypes.bool,
  isMagenta: PropTypes.bool,
};

Facts.defaultProps = {
  bgColorInverted: false,
  linear: false,
  headless: false,
  isMagenta: true,
  title: '',
  overline: '',
};

export default Facts;
