import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { Link } from 'gatsby-theme-material-ui';

import { Box, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import Typography from './theme/Typography';

function MarkdownHeading({ level, children }) {
  let variant;
  switch (level) {
    case 1:
      variant = 'h1';
      break;
    case 2:
      variant = 'h2';
      break;
    case 3:
      variant = 'h3';
      break;
    case 4:
      variant = 'subtitle2';
      break;
    default:
      variant = 'h2';
      break;
  }
  return (
    <Typography
      sx={(theme) => ({
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
      })}
      gutterBottom
      variant={variant}
    >
      {children}
    </Typography>
  );
}

MarkdownHeading.propTypes = {
  level: PropTypes.number,
  children: PropTypes.node.isRequired,
};

MarkdownHeading.defaultProps = {
  level: 2,
};

function MarkdownListItem({ children }) {
  return (
    <Box component="li">
      <Typography component="span">{children}</Typography>
    </Box>
  );
}

MarkdownListItem.propTypes = {
  children: PropTypes.node.isRequired,
};

function MarkdownTable({ children }) {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        {children}
      </Table>
    </TableContainer>
  );
}

MarkdownTable.propTypes = {
  children: PropTypes.node.isRequired,
};

function MarkdownTableCell({ children }) {
  return (
    <TableCell>
      <Typography>{children}</Typography>
    </TableCell>
  );
}

MarkdownTableCell.propTypes = {
  children: PropTypes.node.isRequired,
};

function MarkdownTableRow({ children }) {
  return <TableRow>{children}</TableRow>;
}

MarkdownTableRow.propTypes = {
  children: PropTypes.node.isRequired,
};

function MarkdownTableBody({ children }) {
  return <TableBody>{children}</TableBody>;
}

MarkdownTableBody.propTypes = {
  children: PropTypes.node.isRequired,
};

function MarkdownTableHead({ children }) {
  return <TableHead>{children}</TableHead>;
}

MarkdownTableHead.propTypes = {
  children: PropTypes.node.isRequired,
};

function MarkdownParagraph({ children }) {
  return (
    <Typography variant="body2" paragraph sx={{ fontWeight: 500 }}>
      {children}
    </Typography>
  );
}

MarkdownParagraph.propTypes = {
  children: PropTypes.node.isRequired,
};

function MarkdownImage({ src, alt }) {
  return <Box component="img" src={src} alt={alt} sx={{ maxWidth: '100%', height: 'auto' }} />;
}

function MarkdownBlockquote({ children }) {
  return (
    <Typography
      variant="body2"
      component="blockquote"
      sx={(theme) => ({
        borderLeft: `6px solid ${theme.palette.secondary.main}`,
        paddingLeft: '16px',
        fontStyle: 'italic',
      })}
      paragraph
    >
      {children}
    </Typography>
  );
}

MarkdownBlockquote.propTypes = {
  children: PropTypes.node.isRequired,
};

const components = {
  h1: MarkdownHeading,
  h2: MarkdownHeading,
  h3: MarkdownHeading,
  h4: MarkdownHeading,
  p: MarkdownParagraph,
  blockquote: MarkdownBlockquote,
  a: Link,
  li: MarkdownListItem,
  image: MarkdownImage,
  table: MarkdownTable,
  thead: MarkdownTableHead,
  tbody: MarkdownTableBody,
  tr: MarkdownTableRow,
  td: MarkdownTableCell,
};

function StrapiMarkdown({ source }) {
  return (
    <ReactMarkdown
      components={components}
      rehypePlugins={[rehypeRaw]}
      transformImageUri={(uri) => (uri.startsWith('http') ? uri : `${process.env.CMS_URL}${uri}`)}
    >
      {source}
    </ReactMarkdown>
  );
}

StrapiMarkdown.propTypes = {
  source: PropTypes.node.isRequired,
};

export default StrapiMarkdown;
