import React from 'react';
import PropTypes from 'prop-types';
import Typography from './theme/Typography';
import BlockHeadline from './layout/BlockHeadline';
import StrapiMarkdown from './StrapiMarkdown';

function HeroContent({ heroText, heroOverline, heroTitle, bgColorInverted, isMagenta }) {
  return (
    <>
      <BlockHeadline isMagenta={isMagenta} overline={heroOverline} content={heroTitle} sizeRestriction="none" />
      <Typography
        // eslint-disable-next-line react/no-array-index-key
        component="span"
        color={bgColorInverted ? 'textSecondary' : 'textPrimary'}
        variant="body1"
        sx={(theme) => ({
          marginBottom: '24px !important',
          display: 'block',
          '& a': {
            color: isMagenta ? theme.palette.link.primary : theme.palette.link.secondary,
            '&:hover': {
              color: isMagenta ? theme.palette.link.primary : theme.palette.link.secondary,
            },
          },
          '& ul': {
            paddingLeft: 0,
            '&.twoColumns': {
              columns: 2,
            },
          },
          '& li': {
            listStyle: 'none',
            position: 'relative',
            paddingLeft: '0.8rem',
            '&:before': {
              position: 'absolute',
              top: '0.7rem',
              left: 0,
              content: '""',
              display: 'block',
              width: '0',
              height: '0',
              borderStyle: 'solid',
              borderWidth: '0.15rem 0 0.15rem 0.3rem',
              borderColor: `transparent transparent transparent ${
                isMagenta ? theme.palette.secondary.main : theme.palette.green.main
              }`,
            },
          },
        })}
      >
        <StrapiMarkdown source={heroText.data.text} />
      </Typography>
    </>
  );
}

HeroContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  heroText: PropTypes.object,
  heroOverline: PropTypes.string,
  heroTitle: PropTypes.string,
  bgColorInverted: PropTypes.bool,
  isMagenta: PropTypes.bool,
};

HeroContent.defaultProps = {
  heroText: null,
  heroOverline: '',
  heroTitle: '',
  bgColorInverted: false,
  isMagenta: true,
};

export default HeroContent;
