import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { ReactSVG } from 'react-svg';

import Container from './theme/Container';

import IconWhatsapp from '../assets/images/contact/whatsapp.svg';
import IconTelegram from '../assets/images/contact/telegram.svg';
import IconSignal from '../assets/images/contact/signal.svg';

function WorldRecordHotline({ isMagenta }) {
  const intl = useIntl();
  return (
    <Box sx={{ backgroundColor: '#121212' }}>
      <Container>
        <Grid container justifyContent="center">
          <Grid
            item
            xs={12}
            md={8}
            sx={(theme) => ({
              position: 'relative',
              [theme.breakpoints.up('md')]: {
                alignItems: 'center',
                display: 'flex',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                backgroundColor: isMagenta ? theme.palette.secondary.main : theme.palette.green.main,
                height: '100%',
                left: '-24px',
                position: 'absolute',
                width: 'calc(100% + 48px)',
                [theme.breakpoints.up('md')]: {
                  left: '-100vw',
                  transform: 'skewX(-20deg)',
                  width: 'calc(100% + 100vw)',
                },
              })}
            />
            <Box
              component="p"
              sx={(theme) => ({
                fontSize: '24px',
                fontStyle: 'italic',
                fontWeight: '900',
                lineHeight: '28px',
                margin: '24px 0',
                position: 'relative',
                textAlign: 'center',
                textTransform: 'uppercase',
                [theme.breakpoints.up('md')]: {
                  paddingRight: '50px',
                  textAlign: 'left',
                },
              })}
            >
              <span>{intl.formatMessage({ id: 'worldrecordContact.headline' })}: </span>
              <Box
                component="span"
                sx={(theme) => ({
                  display: 'block',
                  whiteSpace: 'nowrap',
                  [theme.breakpoints.up('md')]: {
                    display: 'inline',
                  },
                })}
              >
                {intl.formatMessage({ id: 'worldrecordContact.phoneNumber' })}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: {
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-end',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'center',
                padding: '24px 0',
                [theme.breakpoints.up('md')]: {
                  justifyContent: 'flex-end',
                  padding: '8px 0 0',
                  position: 'relative',
                  right: '-12px',
                },
                '& svg': {
                  margin: '0 12px',
                },
              })}
            >
              <ReactSVG src={IconWhatsapp} />
              <ReactSVG src={IconTelegram} />
              <ReactSVG src={IconSignal} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

WorldRecordHotline.propTypes = {
  isMagenta: PropTypes.bool,
};

WorldRecordHotline.defaultProps = {
  isMagenta: true,
};

export default WorldRecordHotline;
