import React from 'react';
import PropTypes from 'prop-types';

import Hidden from '@mui/material/Hidden';
import { Box } from '@mui/material';

import { GatsbyImage, Link } from './theme';

const bannerStyles = (theme) => ({
  marginBottom: '60px',
  marginTop: '60px',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    marginLeft: '-10vw',
    width: 'calc(100% + 20vw)',
  },
});

function Banner({ images, link }) {
  return (
    <Box
      sx={[
        (theme) => ({
          [theme.breakpoints.up('md')]: {
            padding: '0 10vw',
          },
        }),
      ]}
    >
      <Link to={link}>
        <Hidden smUp>
          <GatsbyImage
            image={images.imageXs.localFile.childImageSharp.gatsbyImageData}
            alt={images.imageXs.alternativeText ? images.imageXs.alternativeText : ''}
            sx={bannerStyles}
          />
        </Hidden>
        <Hidden mdUp smDown>
          <GatsbyImage
            image={images.imageSm.localFile.childImageSharp.gatsbyImageData}
            alt={images.imageSm.alternativeText ? images.imageSm.alternativeText : ''}
            sx={bannerStyles}
          />
        </Hidden>
        <Hidden mdDown>
          <GatsbyImage
            image={images.imageMd.localFile.childImageSharp.gatsbyImageData}
            alt={images.imageMd.alternativeText ? images.imageMd.alternativeText : ''}
            sx={bannerStyles}
          />
        </Hidden>
      </Link>
    </Box>
  );
}

Banner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};

export default Banner;
