import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import Facts from '../components/Facts';
import Container from '../components/theme/Container';

import { Layout, HeadSection, BlockHeadline } from '../components/layout';
import Hero from '../components/Hero';
import Testimonials from '../components/Testimonials';
import Blogs from '../components/Blogs';
import Newsletter from '../components/Newsletter';
import Sponsors from '../components/Sponsors';
import Organizer from '../components/Organizer';
import WorldRecordHotline from '../components/WorldRecordHotline';
import { GatsbyImage } from '../components/theme';
import SEO from '../components/Seo';
import SaveTheDate from '../components/SaveTheDate';
import Banner from '../components/Banner';

function PageTemplate({ data }) {
  const components = data.strapiPage.content.map((component) => {
    // eslint-disable-next-line no-underscore-dangle
    switch (component.__typename) {
      case 'STRAPI__COMPONENT_BLOCKS_CONTENT_FACTS':
        // Render ComponentType1 and access its fields directly from `component`
        return (
          <Facts
            key={component.id}
            title={component.title}
            overline={component.overline}
            facts={component.facts}
            bgColorInverted={component.bgColorInverted}
            headless={component.headless}
            linear={component.linear}
            isMagenta={component.isMagenta}
          />
        );
      case 'STRAPI__COMPONENT_BLOCKS_HERO':
        // Render ComponentType2 and access its fields directly from `component`
        return (
          <Hero
            key={component.id}
            banner={component.banner}
            bgColorInverted={component.bgColorInverted}
            content={{
              heroTitle: component.Title,
              heroText: component.text,
              heroOverline: component.Overline,
              heroImages: component.Images,
              heroImage: component.heroImage,
            }}
            contentAlign={component.contentAlign}
            download={component.download}
            isMagenta={component.isMagenta}
          />
        );
      case 'STRAPI__COMPONENT_BLOCKS_HEADLINE':
        return (
          <div key={component.id}>
            {component.isPageHeadline ? (
              <HeadSection>
                <BlockHeadline
                  align={component.align}
                  content={component.headline}
                  sizeRestriction={component.sizeRestriction}
                  variant={component.variant}
                  isMagenta={component.isMagenta}
                />
              </HeadSection>
            ) : (
              <BlockHeadline
                align={component.align}
                content={component.headline}
                sizeRestriction={component.sizeRestriction}
                variant={component.variant}
                isMagenta={component.isMagenta}
              />
            )}
          </div>
        );
      case 'STRAPI__COMPONENT_BLOCKS_TESTIMONIALS':
        return (
          <Testimonials
            key={component.id}
            testimonials={data.allStrapiTestemonial.nodes}
            title={component.title}
            overline={component.overline}
            isMagenta={component.isMagenta}
          />
        );
      case 'STRAPI__COMPONENT_BLOCKS_BLOG':
        return (
          <Blogs
            isMagenta={component.isMagenta}
            key={component.id}
            blogs={data.allStrapiBlog.nodes}
            title={component.title}
            overline={component.overline}
            showLink={component.showLink}
          />
        );
      case 'STRAPI__COMPONENT_BLOCKS_NEWSLETTER':
        return <Newsletter key={component.id} themeBase={component.themeBase} />;
      case 'STRAPI__COMPONENT_BLOCKS_EVENT_SPONSORS':
        return <Sponsors key={component.id} sponsor={component.sponsor} bgColorInverted={component.bgColorInverted} />;
      case 'STRAPI__COMPONENT_BLOCKS_ORGANIZER':
        return (
          <Organizer
            key={component.id}
            isMagenta={component.isMagenta}
            content={{
              factsOverline: component.overline,
              factsTitle: component.title,
              factsInfo: component.infoText,
              factsContent: component.facts,
            }}
          />
        );
      case 'STRAPI__COMPONENT_BLOCKS_HOTLINE':
        return <WorldRecordHotline key={component.id} isMagenta={component.isMagenta} />;
      case 'STRAPI__COMPONENT_BLOCKS_BANNER':
        return (
          <Banner
            images={{ imageXs: component.imageXs, imageSm: component.imageSm, imageMd: component.imageMd }}
            link={component.link}
            key={component.id}
          />
        );
      case 'STRAPI__COMPONENT_BLOCKS_SAVE_THE_DATE':
        return (
          <SaveTheDate
            isMagenta={component.isMagenta}
            key={component.id}
            backgroundImage={component.saveTheDateBackgroundImage}
            title={component.saveTheDateTitle.data.title}
            text={component.saveTheDateText.data.text}
            buttonLink={component.buttonLink}
            buttonText={component.buttonText}
            showButton={component.showButton}
          />
        );
      case 'STRAPI__COMPONENT_BLOCKS_IMAGE':
        return (
          <Box
            key={component.id}
            sx={{
              display: 'block',
              padding: '100px 0',
              '& + div': {
                paddingTop: '100px',
              },
            }}
          >
            <Container>
              {component.headless ? null : (
                <BlockHeadline
                  isMagenta={component.isMagenta}
                  overline={component.overline}
                  content={component.title}
                  variant="h2"
                />
              )}
              <GatsbyImage
                image={component.image.localFile.childImageSharp.gatsbyImageData}
                alt={component.image.alternativeText ? component.image.alternativeText : null}
                sx={{
                  marginTop: component.fullWidth ? 0 : '3%',
                  marginLeft: component.fullWidth ? 0 : '15%',
                  marginRight: component.fullWidth ? 0 : '15%',
                  marginBottom: component.fullWidth ? 0 : '5%',
                }}
              />
            </Container>
          </Box>
        );
      // Handle other component types
      default:
        return null;
    }
  });

  return <Layout>{components}</Layout>;
}

export function Head({ data }) {
  const { seoData } = data.strapiPage;
  if (!seoData) return <SEO />;
  return <SEO title={seoData.MetaTitle} description={seoData.MetaDescription} />;
}

PageTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query GetSinglePage($fullSlug: String) {
    strapiPage(fullSlug: { eq: $fullSlug }) {
      seoData {
        MetaDescription
        MetaTitle
      }
      content {
        __typename
        ... on STRAPI__COMPONENT_BLOCKS_HERO {
          id
          Title
          Overline
          bgColorInverted
          contentAlign
          download
          isMagenta
          text {
            data {
              text
            }
          }
          heroImage {
            tabText
            id
            image {
              name
              id
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCKS_CONTENT_FACTS {
          id
          title
          overline
          linear
          bgColorInverted
          headless
          isMagenta
          facts {
            headline
            icon
            text {
              data {
                text
              }
            }
            link {
              text
              url
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCKS_HEADLINE {
          id
          headline
          align
          sizeRestriction
          variant
          isPageHeadline
          isMagenta
        }
        ... on STRAPI__COMPONENT_BLOCKS_TESTIMONIALS {
          id
          overline
          title
          isMagenta
        }
        ... on STRAPI__COMPONENT_BLOCKS_BLOG {
          id
          overline
          title
          showLink
          isMagenta
        }
        ... on STRAPI__COMPONENT_BLOCKS_NEWSLETTER {
          id
          themeBase
        }
        ... on STRAPI__COMPONENT_BLOCKS_EVENT_SPONSORS {
          id
          bgColorInverted
          sponsor {
            title
            id
            sponsorData {
              link
              id
              image {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCKS_ORGANIZER {
          id
          overline
          title
          isMagenta
          infoText {
            data {
              infoText
            }
          }
          facts {
            headline
            icon
            text {
              data {
                text
              }
            }
            link {
              text
              url
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCKS_HOTLINE {
          id
          isMagenta
        }
        ... on STRAPI__COMPONENT_BLOCKS_IMAGE {
          id
          headless
          title
          overline
          fullWidth
          isMagenta
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alternativeText
          }
        }
        ... on STRAPI__COMPONENT_BLOCKS_BANNER {
          id
          link
          imageXs {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          imageSm {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          imageMd {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCKS_SAVE_THE_DATE {
          id
          isMagenta
          saveTheDateTitle: title {
            data {
              title
            }
          }
          buttonLink
          buttonText
          showButton
          saveTheDateText: text {
            data {
              text
            }
          }
          saveTheDateBackgroundImage: backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allStrapiTestemonial {
      nodes {
        testimonialAllign: Allignment
        testimonialCompany: Company
        testimonialImage: Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        testimonialName: Name
        testimonialQuote: Quote {
          data {
            Quote
          }
        }
        testimonialId: id
      }
    }
    allStrapiBlog(sort: { fields: Date, order: DESC }, filter: { Website: { ne: false } }, limit: 2) {
      nodes {
        Teaser
        Cover {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        Slug
        Date
        id
        Title
        Subtitle
        Text {
          data {
            Text
          }
        }
      }
    }
  }
`;

export default PageTemplate;
