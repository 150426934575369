import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { ReactSVG } from 'react-svg';

import Container from './theme/Container';

const headlineStylesLightMode = (theme) => ({
  color: '#666666',
  fontSize: theme.typography.pxToRem(18),
  fontStyle: 'italic',
  fontWeight: '900',
  margin: '0 0 15px',
  textTransform: 'uppercase',
});

const headlineStylesDarkMode = (theme) => ({
  color: '#ffffff',
  fontSize: theme.typography.pxToRem(18),
  fontStyle: 'italic',
  fontWeight: '900',
  margin: '0 0 15px',
  textTransform: 'uppercase',
});

function Sponsors({ sponsor, inline, bgColorInverted }) {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: bgColorInverted ? theme.palette.background.default : theme.palette.grey[100],
        paddingBottom: '30px',
        paddingTop: '48px',
      })}
    >
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} sx={{ marginBottom: '20px' }}>
            {sponsor.map((spons) => {
              return (
                <React.Fragment key={spons.id}>
                  <Grid container justifyContent="center">
                    <Box component="h3" sx={bgColorInverted ? headlineStylesDarkMode : headlineStylesLightMode}>
                      {spons.title}
                    </Box>
                  </Grid>
                  <Grid container justifyContent="center">
                    {spons.sponsorData.map((image) => {
                      return (
                        <Grid key={image.id} item xs={6} sm={4}>
                          {image?.link ? (
                            <a href={image.link} rel="noreferrer" target="_blank">
                              <ReactSVG src={image.image.localFile.publicURL} />
                            </a>
                          ) : (
                            <ReactSVG src={image.image.localFile.publicURL} />
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

Sponsors.propTypes = {
  inline: PropTypes.bool,
  bgColorInverted: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sponsor: PropTypes.array.isRequired,
};

Sponsors.defaultProps = {
  inline: false,
  bgColorInverted: false,
};

export default Sponsors;
