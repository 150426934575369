import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-react-intl';

import { Box, Tabs, Tab, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Container from './theme/Container';
import HeroContent from './HeroContent';
import Banner from './Banner';
import Button from './theme/Button';
import AppStoreLinks from './AppStoreLinks';
import Fact from './Fact';
import BlockHeadline from './layout/BlockHeadline';
import { colors, typo } from '../gatsby-theme-material-ui-top-layout/theme';
import StrapiMarkdown from './StrapiMarkdown';

// ToDo Create a global turquoise colour profile for colour #27c299

function Organizer({ content, isMagenta }) {
  const intl = useIntl();
  const { factsOverline, factsTitle, factsContent, factsInfo } = content;

  return (
    <Box sx={{ padding: '60px 0' }}>
      <Container>
        <Grid justifyContent="space-between" alignItems="center" spacing={3} container>
          <Grid xs={12} sm={5} md={6}>
            <Typography
              sx={(theme) => ({
                marginBottom: '32px',
                left: '-5px',
                position: 'relative',
                // Roboto-BlackItalic
                fontSize: theme.typography.pxToRem(36),
                [theme.breakpoints.up('xs')]: {
                  fontSize: theme.typography.pxToRem(36),
                },
                [theme.breakpoints.up('md')]: {
                  fontSize: theme.typography.pxToRem(44),
                },
                fontWeight: theme.typography.fontWeightBolder,
                lineHeight: 1.09,
                textTransform: 'uppercase',
                fontStyle: 'italic',
                letterSpacing: 'normal',
              })}
              color="inherit"
            >
              <Typography
                variant="caption"
                sx={(theme) => ({
                  display: 'block',
                  paddingLeft: '5px',
                  // Roboto-Blackitalic
                  fontSize: theme.typography.pxToRem(16),
                  fontWeight: typo.weights.bolder,
                  color: isMagenta ? theme.palette.secondary.main : theme.palette.green.main,
                  textTransform: 'uppercase',
                  fontStyle: 'italic',
                  letterSpacing: -0.09,
                  [theme.breakpoints.up('lg')]: {
                    fontSize: theme.typography.pxToRem(20),
                  },
                })}
              >
                {factsOverline}
              </Typography>
              {factsTitle}
              <Box
                component="span"
                sx={(theme) => ({
                  color: isMagenta ? theme.palette.secondary.main : theme.palette.green.main,
                  fontSize: '50px',
                  position: 'relative',
                  left: '2px',
                  lineHeight: 0,
                })}
              >
                .
              </Box>
            </Typography>
            <Box
              sx={(theme) => ({
                '& a': {
                  color: isMagenta ? theme.palette.link.primary : theme.palette.link.secondary,
                  '&:hover': {
                    color: isMagenta ? theme.palette.link.primary : theme.palette.link.secondary,
                  },
                },
              })}
            >
              <StrapiMarkdown source={factsInfo.data.infoText} />
            </Box>
            {!isMagenta ? (
              <Typography
                align="left"
                sx={{
                  paddingTop: '32px',
                  '& > a': {
                    backgroundColor: '#27c299',
                    '&:hover, &:focus': {
                      backgroundColor: '#05a077',
                    },
                  },
                }}
              >
                <Button variant="contained" color="secondary" href="https://admin.komm-kickern.de">
                  {intl.formatMessage({ id: 'organizer.cta' })}
                </Button>
              </Typography>
            ) : (
              <Button
                sx={{ marginTop: '32px' }}
                variant="contained"
                color="secondary"
                href="https://admin.komm-kickern.de"
              >
                {intl.formatMessage({ id: 'organizer.cta' })}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} sm={7} md={6}>
            <Box
              sx={{
                paddingTop: '60px',
                '& > div': {
                  maxWidth: '100% !important',
                },
              }}
            >
              {factsContent.map((fact, index) => (
                <Fact
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  content={fact}
                  fullContent
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Organizer;
